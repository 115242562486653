// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-api-js": () => import("./../src/pages/api.js" /* webpackChunkName: "component---src-pages-api-js" */),
  "component---src-pages-faq-js": () => import("./../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-proof-js": () => import("./../src/pages/proof.js" /* webpackChunkName: "component---src-pages-proof-js" */),
  "component---src-pages-subpage-index-js": () => import("./../src/pages/subpage/index.js" /* webpackChunkName: "component---src-pages-subpage-index-js" */)
}

